import { Link } from "react-router-dom";
import Accodion from "../Helpers/Accodion";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { useEffect } from "react";

export default function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="faq-page-wrapper w-full mb-10">
        <div className="page-title w-full">
          <PageTitle
            title="Frequently Asked Questions"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "FAQ", path: "/faq" },
            ]}
          />
        </div>
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="lg:w-1/2 w-full mb-10 lg:mb-0">
              <div>
                <h1 className="text-qblack font-bold text-[22px] mb-4">
                  General Questions
                </h1>
                <div className="flex flex-col space-y-7 justify-between">
                  <Accodion
                    title="1. What is Items.lk?"
                    des="Items.lk is an online store offering high-quality mobile accessories, including phone cases, screen protectors, chargers, headphones, and more."
                  />
                  <Accodion
                    init
                    title="2. Where are you located?"
                    des="We are an online store and ship to locations Islandwide in Sri Lanka. Our Physical Store is Located at 158 Kawdana Road Dehiwela."
                  />
                  <Accodion
                    title="3. How can I contact customer support?"
                    des="You can reach us via email at itemslkstore@gmail.com or through the contact form on our website."
                  />
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-qblack font-bold text-[22px] mb-4">
                  Orders & Payments
                </h1>
                <div className="flex flex-col space-y-7 justify-between">
                  <Accodion
                    title="1. What payment methods do you accept?"
                    des="We accept credit/debit cards, Koko, and other local payment options depending on your location."
                  />
                  <Accodion
                    init
                    title="2. How do I track my order?"
                    des="Once your order is shipped, you’ll receive a tracking number via email or SMS. Use this to track your order on our website or the courier’s tracking page."
                  />
                  <Accodion
                    title="3. Can I cancel or modify my order after placing it?"
                    des="Orders can be modified or canceled within 24 hours of placement. Contact us immediately for assistance."
                  />
                  <Accodion
                    title="4. Is it safe to shop on your website?"
                    des="Yes, our website uses secure encryption to protect your payment and personal details."
                  />
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-qblack font-bold text-[22px] mb-4">
                  Shipping & Delivery
                </h1>
                <div className="flex flex-col space-y-7 justify-between">
                  <Accodion
                    title="1. What is your shipping policy?"
                    des="We offer standard and express shipping. Delivery times vary based on your location. Check our shipping page for details."
                  />
                  <Accodion
                    init
                    title="2. Do you offer free shipping?"
                    des="Yes, we offer free shipping on orders over Rs 2500"
                  />
                  <Accodion
                    title="3. What should I do if my package is delayed or lost?"
                    des="Please contact our support team with your order number for assistance."
                  />
                  <Accodion
                    title="4. What is the Delivery Duration?"
                    des="Usually We Deliver Within 2-3 Working Days and it may vary based on your location."
                  />
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-qblack font-bold text-[22px] mb-4">
                  Products
                </h1>
                <div className="flex flex-col space-y-7 justify-between">
                  <Accodion
                    title="1. Are your products compatible with all phone models?"
                    des="Each product description includes a compatibility list. Please check before purchasing."
                  />
                  <Accodion
                    init
                    title="2. What if I receive a defective or incorrect product?"
                    des="Contact us within 7 days of receiving the product. We’ll replace or refund it at no additional cost."
                  />
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-qblack font-bold text-[22px] mb-4">
                  Returns & Refunds
                </h1>
                <div className="flex flex-col space-y-7 justify-between">
                  <Accodion
                    title="1. We accept returns within 7 days of purchase if the item is unused and in its original packaging."
                    des="Each product description includes a compatibility list. Please check before purchasing."
                  />
                  <Accodion
                    init
                    title="2. How long does it take to process a refund?"
                    des="Refunds are processed within 5-7 business days after we receive the returned item."
                  />
                  <Accodion
                    init
                    title="3. Do you offer exchanges?"
                    des="Yes, we offer exchanges for defective or incorrect items."
                  />
                </div>
              </div>
              <div className="mt-10">
                <h1 className="text-qblack font-bold text-[22px] mb-4">
                  Promotions & Discounts
                </h1>
                <div className="flex flex-col space-y-7 justify-between">
                  <Accodion
                    title="1. Do you offer discounts or promotions?"
                    des="Yes, we regularly have promotions and discounts. Sign up for our newsletter to stay updated."
                  />
                  <Accodion
                    init
                    title="2. Can I use multiple discount codes in one order?"
                    des="Only one discount code can be applied per order unless stated otherwise."
                  />
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="bg-white sm:p-10 p-5">
                <div className="title flex flex-col items-center">
                  <h1 className="lg:text-[34px] text-xl font-bold text-qblack">
                    Have Any Qustion
                  </h1>
                  <span className="-mt-5 block">
                    <svg
                      width="354"
                      height="30"
                      viewBox="0 0 354 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                        stroke="#FFBB38"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </div>
                <div className="inputs mt-5">
                  <div className="mb-4">
                    <InputCom
                      label="Frist Name*"
                      placeholder="Demo Name"
                      name="first_name"
                      inputClasses="h-[50px]"
                    />
                  </div>
                  <div className="mb-4">
                    <InputCom
                      label="Email Address*"
                      placeholder="info@items.lk"
                      name="email"
                      inputClasses="h-[50px]"
                    />
                  </div>
                  <div className="mb-5">
                    <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                      Message*
                    </h6>
                    <textarea
                      placeholder="Type your message here"
                      className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                    ></textarea>
                  </div>
                  <div>
                    <button>
                      <div className="black-btn text-sm font-semibold w-full h-[50px] flex justify-center items-center">
                        <span>Send Now</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
