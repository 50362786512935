import React from "react";
import { useMediaQuery } from "react-responsive";

const Pagination = ({
  totalLength,
  itemsPerPage,
  currentPage,
  changePageNumber,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const visiblePages = isMobile ? 3 : 7;
  const totalPages = Math.ceil(totalLength / itemsPerPage);

  const renderPageNumbers = () => {
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage < visiblePages - 1) {
      startPage = Math.max(1, endPage - visiblePages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`cursor-pointer px-3 py-1 rounded-md ${
            i === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          onClick={() => changePageNumber(i)}
        >
          {i}
        </li>
      );
    }

    if (startPage > 2) {
      pages.unshift(
        <li key="start-ellipsis" className="px-3 py-1">
          ...
        </li>
      );
    }

    if (endPage < totalPages - 1) {
      pages.push(
        <li key="end-ellipsis" className="px-3 py-1">
          ...
        </li>
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <li
          key={totalPages}
          className={`cursor-pointer px-2 py-1 rounded-md ${
            totalPages === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 hover:bg-gray-300"
          }`}
          onClick={() => changePageNumber(totalPages)}
        >
          {totalPages}
        </li>
      );
    }

    return pages;
  };

  return (
    <ul className="flex justify-center items-center space-x-2">
      <li
        className={`cursor-pointer px-2 py-1 rounded-md ${
          currentPage === 1 ? "bg-gray-300" : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={() => currentPage > 1 && changePageNumber(1)}
      >
        &lt;&lt;
      </li>
      <li
        className={`cursor-pointer px-2 py-1 rounded-md ${
          currentPage === 1 ? "bg-gray-300" : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={() => currentPage > 1 && changePageNumber(currentPage - 1)}
      >
        &lt;
      </li>
      {renderPageNumbers()}
      <li
        className={`cursor-pointer px-2 py-1 rounded-md ${
          currentPage === totalPages
            ? "bg-gray-300"
            : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={() =>
          currentPage < totalPages && changePageNumber(currentPage + 1)
        }
      >
        &gt;
      </li>
      <li
        className={`cursor-pointer px-2 py-1 rounded-md ${
          currentPage === totalPages
            ? "bg-gray-300"
            : "bg-gray-200 hover:bg-gray-300"
        }`}
        onClick={() => currentPage < totalPages && changePageNumber(totalPages)}
      >
        &gt;&gt;
      </li>
    </ul>
  );
};

export default Pagination;
